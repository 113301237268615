html {
	scroll-behavior: smooth;
}

.header {
  width:90vw;
  margin:auto;
}

.title {
  font-size: calc(1.5rem + 1.2vw);
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

.header_links
{
  font-size: calc(.5rem + .6vw);
  color: #2A4663;
  text-decoration: inherit;
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.contact_button {
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  -webkit-transition: color .2s ease-out;
  -moz-transition: color .2s ease-out;
  -ms-transition: color .2s ease-out;
  -o-transition: color .2s ease-out;
  transition: color .2s ease-out;
  //border-radius: 100px;
}

.contact_button:link, .contact_button:visited {
  background-color: #0165E1;
  color: white;
  //border: 2px solid #0165E1;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.contact_button:hover, .contact_button:active {
  background-color: white;
  border: 1px solid #0165E1;
  color: #0165E1;
}

.footer_text:hover{
  color: #0165E1;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  //border: 1px solid black;
  color: #0087ca;
  //border: 1px solid black;
  padding-bottom:0px;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 60%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 20%;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.header_links_div
{
  //border: 1px solid black;
  margin-top: -5vh;
  //padding:20px;
}

a:hover {
  color: #0165E1;
}

.divHeader
{
  //box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 1), 0 6px 20px 0 rgba(0, 0, 0, .1);
  justify-content:space-between;
  display:flex;
  padding:15px;
  //border-radius: 20px;
}

.shadow
{
  border-radius: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0), 0 6px 20px 0 rgba(0, 0, 0, .2);
  //border: 0px solid white;
}

.subtitle {
  font-size: calc(2rem + 2vw);
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  margin-top:5vh;
}

.headerDiv {
  //border: 1px solid black;
}

.body_title {
  font-size: calc(1rem + .75vh);
  text-align: center;
}

.body_text {
  font-size: calc(1rem + 1vw);
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-top: -2vh;
}

.body {
  font-family:Arial, sans-serif;
  //text-align: center;
  //margin-bottom: 5rem;
}

.body_section_intro
{
  width:50vw;
  margin: auto;
  position: relative;
  //min-height: 100vh;
}

.section_services
{
  display: flex;
  flex-direction: row;
  //width:85vw;
  //margin-inline:auto;
  //border: 1px solid #F7F7F9;
  background-color: #F7F7F9;
  padding-bottom:5em;
  padding-inline:7.5vw;
  padding-top:2em;
}

.section_work
{
  display: flex;
  flex-direction: row;
  width:85vw;
  margin:auto;
  margin-top:10vh;
  margin-bottom:10vh;
  //border: 1px solid black;
}

.section_footer
{
  display: flex;
  flex-direction: row;
  background-color: #292929;
  padding-bottom:5em;
  padding-inline:7.5vw;
  padding-top:2em;
}

.services_title
{
  font-size: calc(2rem + 2vw);
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.footer_title
{
  font-size: calc(2rem + 2vw);
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.tech_title
{
  font-size: calc(1em + 1.2vw);
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  color: #0165E1;
}

.tech_subtitle
{
  font-size: calc(1em + 1.0vw);
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  //border: 1px solid black;
  margin-top:20px;
}

.footer_title_text
{
  color:#0165E1;
  font-size: calc(.7rem + .5vw);
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
}

.footer_text
{
   color:white;
}

.tech_div
{
  //border: 1px solid black;
  //margin:10px;
  width:95%;
  height:55vh;
  margin-bottom:8vh;
}

.work_div
{
  margin:10px;
  width:95%;
  margin-bottom:8vh;
}

.tech_table
{
  //border: 1px solid black;
  margin-left: -50px;
}

.work_table
{
  margin-left: -50px;
}

.footer_table
{
  margin-left: -50px;
  //border: 1px solid white;
  width:100%;
  //padding-top: 5vh;
}

.footer_div
{
  margin:10px;
  padding:1px;
  width:95%;
  //border: 1px solid white;
  height:50vh;
}

.tech_blurb
{
  font-size: calc(.6rem + .6vw);
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  line-height: 26px;
  margin-top:-1vh;
}

.footer_list_items
{
  font-size: calc(.6rem + .5vw);
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  line-height: 28px;
  margin-top:-1vh;
}

.services_blurb
{
  color: black;
  font-size: calc(.8rem + .5vw);
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  width:75%;
  margin-top: -5vh;
  line-height: 26px;
}

.footer_blurb
{
  font-size: calc(.6rem + .5vw);
  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  width:75%;
  margin-top: -5vh;
  line-height: 26px;
}

.body_section_services_left
{
  margin-left:3vw;
  width:200vw;
}

.body_section_services_right
{
  margin-right:3vw;
  width:200vw;
  //border: 1px solid white;
  padding-top:3vh;
}


.section_one_div {
  font-family:Arial, sans-serif;
  margin-top: -2.5em;
  margin-bottom: 8em;
}

.footer {
  background-color: #0165E1;
  position: fixed;
	width: 100%;
	bottom: 0;
  padding-top: 1vh;
}

.footer_content {
  text-align: center;
  font-size: calc(.5rem + .6vw);
}

.App-link {
  color: #61dafb;
}

.explore-link {
  color: inherit;
  text-decoration: inherit;
}

.footer_images
{
  width:calc(1rem + .2vw);
  height:calc(1rem + .2vw);
}

.headerParent
{
    padding-bottom: 12vh;
}

.table_mob_view
{
  display: none;
}

@media screen and (min-width: 1100px) {
  .footer_content {
    font-size: calc(.5rem + .5vw);
  }
}

@media screen and (max-width: 800px) {
  .header_links_div{
    display: none;
  }

  .divHeader
  {
    justify-content:center;
    display:flex;
    padding:15px;
  }

  .contact_button_div
  {
    display: none;
  }

  .headerParent
  {
      padding-bottom:2vh;
  }
  .body_section_intro
  {
    width:85vw;
  }

  .section_one_div {
    margin-top: -2.5em;
    margin-bottom: 5em;
  }

  .body_text
  {
    margin-top: -2vh;
    width:90%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 1200px) {
.section_services
  {
    display:block;
    //border: 1px solid black;
    background-color: #F7F7F9;
    padding-bottom:5em;
    padding-top:2em;
  }

  .section_work{
    display:block;
    padding-bottom:5em;
    margin-bottom:0vh;
    //padding-top:2em;
  }

  .section_footer{
    display:block;
    padding-bottom:5em;
    padding-top:.5em;
  }

  .body_section_services_left
  {
    width:90%;
    margin: auto;
    padding-bottom: 5vh;
  }

  .services_title,.footer_title
  {
    text-align: center;
  }

  .services_blurb,.tech_blurb
  {
    text-align: center;
    width:90%;
    margin: auto;
    margin-top: -3vh;
  }

  .footer_blurb
  {
    font-size: calc(.8rem + .6vw);
    text-align: center;
    width:90%;
    margin: auto;
    margin-top: -5vh;
    //line-height: 22px;
  }

  .body_section_services_right
  {
    width:90%;
    margin: auto;
  }
  .tech_table,.work_table,.footer_table
  {
    display: none;
  }
  .table_mob_view
  {
    display:block;
  }

  .tech_subtitle
  {
    text-align: center;
  }
  .tech_blurb,.footer_blurb
  {
    text-align: center;
  }
  .tech_imgs,.work_imgs
  {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .tech_div,.work_div,.footer_div
  {
    height: auto;
    margin-bottom: 8vh;
  }
  .tech_blurb
  {
    font-size: calc(.9rem + .8vw);
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    line-height: 26px;
    margin-top:-1vh;
  }

  .services_blurb
  {
    font-size: calc(1rem + .8vw);
    //font-weight: bold;
  }

  .title{
    font-size: calc(1.8rem + 1.2vw);
  }

  .subtitle {
    font-size: calc(1.8rem + 1.8vw);
    //border: 1px solid black;
    //font-family: "Helvetica", "sans-serif";
    font-family: "Lexend Deca", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    text-align: center;
    margin-top: 3vh;
  }

  .body_text{
    font-size: calc(.8rem + .8vw);
    line-height: 30px;
  }
}


@media screen and (max-width: 800px) {
  .subtitle {
    font-weight: 100;
    font-style: normal;
    text-align: center;
  }
}


@media screen and (min-width: 800px) {
  .title{
    font-size: calc(1.5rem + 1.2vw);
  }

  .body_text{
    font-size: calc(.8rem + .8vw);
  }
}
